import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class Animation {
    constructor() {

        gsap.registerPlugin(ScrollTrigger);

        if (document.querySelector(".animate")) {
            const itemTriggers = document.querySelectorAll(".animate");

            itemTriggers.forEach(item => {
                gsap.from(item, {
                    y: 100,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'easeInOut',
                    scrollTrigger: {
                        trigger: item,
                        start: 'top 92%',
                        //markers: true
                    }
                });
            });
        }

        if (document.querySelector("#sygnet")) {
            const sygnet = document.querySelector("#sygnet");
            const rotationGroup = document.querySelector("#wheel");

            gsap.from(sygnet, {
                scale: 0,
                opacity: 0,
                duration: 0.5,
                delay: 0.3,
                ease: 'easeInOut',
                scrollTrigger: {
                    trigger: sygnet,
                    start: 'top 92%',
                    //markers: true
                }
            });

            gsap.from(rotationGroup, {
                    rotation: 30,
                    duration: 1,
                    transformOrigin: 'center',
                    delay: 2,
                    repeat: -1,
                    repeatDelay: 1,
                    ease: "power3.out",
                });

        }

        if (document.querySelector(".dates__item")) {
            const dateTrigger = document.querySelector(".dates__container");
            const dateItems = document.querySelectorAll(".dates__item");

            gsap.from(dateItems, {
                x: 100,
                opacity: 0,
                duration: 0.5,
                stagger: .25,
                ease: 'easeInOut',
                scrollTrigger: {
                    trigger: dateTrigger,
                    start: 'top 92%',
                    //markers: true
                }
            });

        }

    }
}


export default Animation